import cacheUtils from 'store'

const gameShowList = 'GAME_SHOW_LIST'

export function getGameShowListCache() {
  return cacheUtils.get(gameShowList)
}

export function setGameShowListCache(data) {
  cacheUtils.set(gameShowList, data)
}

const lastThemeLastType = 'LAST_THEME_LAST_TYPE'
export function getLastThemeLastType() {
  return cacheUtils.get(lastThemeLastType)
}

export function setLastThemeLastType(data) {
  cacheUtils.set(lastThemeLastType, data)
}

/** 多币种模式当前游戏选择币种 */
export const gamesWalletCoin = 'GAME_WALLET_COIN'
export function setGameWalletCoin(data) {
  cacheUtils.set(gamesWalletCoin, data)
}

export function getGameWalletCoin() {
  return cacheUtils.get(gamesWalletCoin)
}
